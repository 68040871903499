const faRoot = 'https://static.empori.se/fa/css/';

const faFiles = [
  `${faRoot}fontawesome.min.css`,
  `${faRoot}regular.min.css`,
  `${faRoot}brands.min.css`
];

let faLoaded = false;

function appendFontAwesomePro() {
  faFiles.forEach(file => {
    const faFa = document.createElement('link');
    faFa.href = file;
    faFa.rel = 'stylesheet';
    document.head.appendChild(faFa);
  });
}

export function InitFontAwesome() {
  if (faLoaded) {
    return;
  }

  const links = document.getElementsByTagName('link');

  for (let i = links.length; i--;) {
    if (faFiles.some(link => link === links[i].href)) {
      faLoaded = true;
      return;
    }
  }

  appendFontAwesomePro();
  faLoaded = true;
}