import * as React from 'react';
import { InitFontAwesome } from './Icons/InitFontAwesomePro';
interface FaIconProps {
  clsName: string; // Use <i class="This class from fontawesome.com for now"></i>
  clsAltName?: string;
  spin?: boolean;
  title?: string;
  height?: string;
}

export function FaIcon(props: FaIconProps) {
  React.useEffect(() => {
    InitFontAwesome();
  }, []);

  // For some reason clsName is possibly null, prevent crash by returning null if that is the case
  if (!props.clsName) {
    return null;
  }

  let faClass = props.clsName;

  if (props.clsAltName) {
    faClass += ` ${props.clsAltName}`;
  }

  if (props.spin) {
    faClass += ' fa-spin';
  }

  faClass += ' empori-icon';

  return (
    props.clsName.startsWith('/media') ?
      <img src={props.clsName} className='icon-picker-image' height={props.height} /> :
      <i className={faClass} style={{}} title={props.title}></i>);
}

interface IconNames {
  RegularIconsNames: [];
  BrandIconNames: [];
}

export async function getIconNames(): Promise<IconNames> {
  const response = await fetch('/_empori-static/razor/js/tinymce-plugins/icons.json');
  return await response.json();
}
