import { lazy, Suspense } from 'react';
import { emporiModule } from '@empori/util';
import {
  CookiesViewModel,
  CounterJson,
  FormDetails,
  GoogleMapsJson,
  HiddenTextJson,
  ImageGalleryModel,
  ImageWithInfo,
  InputItem,
  UmbracoSearchModel
} from './empracoTypes';
import { LoadingSkeleton } from './generic/UtilityElements';
import { ThemeIds } from './generic/GridFunctions';

export function render(element: JSX.Element, container: Element | DocumentFragment | null) {
  import(/* webpackChunkName: "react-dom-render" */'react-dom')
    .then(r => r.render(element, container));
}

const GoogleMaps = lazy(() =>
  import(/* webpackChunkName: "google-maps" */'./controls/GoogleMaps'));
const ThumbnailImageGallery = lazy(() =>
  import(/* webpackChunkName: "thumbnail-image-gallery" */'./controls/ImageGalleryWithThumbnails'));
const Cookies = lazy(() =>
  import(/* webpackChunkName: "cookies" */'./controls/Cookies'));
const ReadTime = lazy(() =>
  import(/* webpackChunkName: "read-time" */'./controls/ReadTime'));
const Logotypes = lazy(() =>
  import(/* webpackChunkName: "logotypes" */'./controls/Logotypes'));
const RawPageSlideshow = lazy(() =>
  import(/* webpackChunkName: "raw-page-slideshow" */'./controls/RawPageSlideshow'));
const FormCheckboxes = lazy(() =>
  import(/* webpackChunkName: "form-checkboxes" */'./controls/Forms/FormSelectorCheckboxes'));
const Search = lazy(() =>
  import(/* webpackChunkName: "search" */'./controls/Search'));

emporiModule('emporiLoaded', {
  controls: {
    InitGoogleMaps(element: string, model: GoogleMapsJson) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <GoogleMaps model={model} />
        </Suspense>, document.querySelector(element));
    },
    InitHiddenText(contentElement: string, buttonElement: string, model: HiddenTextJson) {
      import(/* webpackChunkName: "hidden-text" */'./controls/HiddenText')
        .then(hiddenText => hiddenText.default(contentElement, buttonElement, model));
    },
    InitMainNavigation(
      mainNav: HTMLDivElement,
      mobileTrigger: HTMLDivElement,
      secNav: HTMLDivElement,
      secTriggerer: HTMLDivElement,
      searchbar: HTMLDivElement    ) {
      import(/* webpackChunkName: "main-navigation" */'./controls/MainNavigation')
        .then(nav => nav.default(mainNav, mobileTrigger, secNav, secTriggerer,searchbar));
    },
    InitCookies(element: string, model: CookiesViewModel) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <Cookies model={model} />
        </Suspense>, document.querySelector(element));
    },
    InitImageGallery(element: HTMLDivElement, model: ImageGalleryModel) {
      import(/* webpackChunkName: "image-gallery" */'./controls/ImageGallery')
        .then(gallery => gallery.default(element, model));
    },
    InitThumbnailImageGallery(element: string, images: ImageWithInfo[]) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <ThumbnailImageGallery images={images} />
        </Suspense>, document.getElementById(element));
    },
    InitNewsArticle(element: string, calcEl: string) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <ReadTime textToCalc={calcEl} />
        </Suspense>, document.querySelector(element));
    },
    InitBaseCompositionPageListGallery(element: string) {
      import(/* webpackChunkName: "base-page-list-slideshow" */'./controls/BaseCompositionPageListGallery')
        .then(gallery => gallery.default(element));
    },
    InitCounter(counter: CounterJson) {
      import(/* webpackChunkName: "counter" */'./controls/Counter')
        .then(count => count.default(counter));
    },
    InitLogotypes(element: string, items: ImageWithInfo[] | null) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <Logotypes items={items} />
        </Suspense>, document.querySelector(element));
    },
    InitFormSelectorFns(formDetails: FormDetails) {
      import(/* webpackChunkName: "form-fns" */'./controls/Forms/FormSelectorFns')
        .then(fns => fns.default(formDetails));
    },
    InitFormCheckboxes(element: string, input: InputItem) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <FormCheckboxes input={input} />
        </Suspense>, document.getElementById(element));
    },
    InitFaqItems(element: string, enableCollapse: boolean) {
      import(/* webpackChunkName: "faq-items" */'./controls/FaqItem')
        .then(faqItems => faqItems.default(element, enableCollapse));
    },
    InitGridFunctions(themeId: ThemeIds) {
      import(/* webpackChunkName: "grid-fns" */'./generic/GridFunctions')
        .then(grid => grid.default(themeId));
    },
    InitRawPageSlideshow(element: string, items: ImageWithInfo[]) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <RawPageSlideshow items={items} />
        </Suspense>, document.getElementById(element));
    },
    InitScrollToTop() {
      import(/* webpackChunkName: "scroll-tt" */'./controls/ScrollToTop').then(sctt => sctt.default());
    },
    InitUmbracoSearch(element: string, model: UmbracoSearchModel) {
      render(
        <Suspense fallback={<LoadingSkeleton />}>
          <Search searchItem={model} />
        </Suspense>,
        document.querySelector(element)
      );
    }
  }
});